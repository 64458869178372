import React, { useState, useEffect } from "react";
import "../../App.css";

const Top = () => {

  // 通常サイズの画像ファイルのパスを配列として定義
  const images = ["/img/Top1.jpg", "/img/Top2.jpg", "/img/Top3.jpg"];
  // レスポンシブサイズの画像ファイルのパスを配列として定義
  const imagesResponsive = ["/img/Top1_sp.png", "/img/Top2_sp.png", "/img/Top3_sp.png"];
  
  // 現在の画像のインデックスを保持する状態を定義、初期値は0
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  // 画面幅が450px以下かどうかを保持する状態を定義、初期値は現在の画面幅に基づく
  const [isResponsive, setIsResponsive] = useState(window.innerWidth <= 450);
  
  // コンポーネントのマウント時および画面サイズ変更時に実行されるeffect
  useEffect(() => {
    // 画面サイズが変更された時に呼ばれる関数を定義
    const handleResize = () => {
      // 画面幅が450px以下の場合はtrue、それ以外はfalseをセット
      setIsResponsive(window.innerWidth <= 450);
    };
    
    // ウィンドウのリサイズイベントに対して、上で定義したhandleResize関数を追加する
    window.addEventListener('resize', handleResize);

    return () => {
      // コンポーネントがアンマウントされるときに、追加したリサイズイベントのリスナーを削除する
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      // 画像のインデックスを更新して、次の画像を表示
      setCurrentImageIndex(
        (currentIndex) => (currentIndex + 1) % images.length
      );
    }, 700); // 1秒ごとに画像を切り替える

    // コンポーネントがアンマウントされた時に、インターバルをクリアする
    return () => clearInterval(interval);
  }, [images.length]); // images.lengthが変更された時にuseEffectが再実行される

  return (
    <div>
      {/* 現在の画像を表示 */}
      <div id="Home" class="anchor"></div>
      <img
        className="homeImg"
        src={process.env.PUBLIC_URL +  (isResponsive ? imagesResponsive[currentImageIndex] : images[currentImageIndex])}
        alt={` ${currentImageIndex}`}
      />
    </div>
  );
};

export default Top;

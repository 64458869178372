import { useState, useEffect } from "react";

function Scroll() {
  // フォーム入力中はスクロール処理を無効化
  // 画面遷移時にスクロール位置を先頭に移動
  // ("top: 0"はページの最上部,"behavior: instant"はスクロールを即座に行う)
  const [isFormSubmitted] = useState(false);

  useEffect(() => {
    if (!isFormSubmitted) {
      window.scrollTo({ top: 0, behavior: "instant" });
    }
  }, [isFormSubmitted]);
}
export default Scroll;

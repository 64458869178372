import React from "react";
import "../../App.css";
import ImageWithAnimation from "../general/ImageWithAnimation";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import PetsIcon from "@mui/icons-material/Pets";

const CoreValue = () => {
  return (
    <>
      {/* TOPのnavからのアンカーリンクを設定 */}
      <div id="CoreValue" class="anchor"></div>

      <svg className="line">
        <line x1="15%" y1="0" x2="85%" y2="0" />
      </svg>

      <div className="CoreValue">
        <ImageWithAnimation
          // public/から静的ファイルを渡す際に必要（process.env.PUBLIC_URL + ）
          src={process.env.PUBLIC_URL + "/img/CoreValue.png"}
          alt="CoreValue画像"
        />

        <div className="CoreValue_section">
          <p className="CoreValue_title">9つのCore Values</p>
          <p className="CoreValue_subTitle">あるべき姿と持ち続ける価値</p>

          <Link to="/CoreValueSub">
            <Button
              variant="contained"
              style={{
                marginTop: "20px",
                width: "200px",
                backgroundColor: "#ffffff",
                color: "#000000",
                fontWeight: "bold",
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 14px 20px",
                borderRadius: "20px",
                borderColor: "#000000",
                borderWidth: "1px",
                borderStyle: "solid",
              }}
              endIcon={<PetsIcon style={{ color: "#b960fb" }} />}
            >
              <span style={{ marginTop: "2px" }}>Read more</span>
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default CoreValue;

import React from "react";
import "../../App.css";
import ImageWithAnimation from "../general/ImageWithAnimation";

const About = () => {
  return (
    <>
      <svg className="line">
        <line x1="15%" y1="0" x2="85%" y2="0" />
      </svg>

      {/* TOPのnavからのアンカーリンクを設定 */}
      <div id="About" class="anchor"></div>

      <div className="About">
        <ImageWithAnimation
          // public/から静的ファイルを渡す際に必要（process.env.PUBLIC_URL + ）
          src={process.env.PUBLIC_URL + "/img/About.png"}
          alt="About画像"
        />

        {/* <ul className="About_section">
          <li className="empty"><span class="term"> 代 表 </span> |   小堀洋平</li>
          <li className="empty"><span class="term"> 商 号 </span> |   <span id="About_section_name">Rayns Architect 合同会社</span></li>
          <li className="empty"><span class="term"> 設 立 </span> |   2022年8月1日</li>
          <li className="empty"><span class="term"> 事 業 </span> |   各種システム開発・コンサルティング事業・自社サービス開発・運営</li>
          <li className="empty"><span class="term">所在地</span> |   〒160-0023 東京都新宿区西新宿3-3-13 西新宿水間ビル2F</li>
          <li className="empty"><span class="term">E-mail</span > |   <span id="About_section_name">info@rayns.jp</span></li>
        </ul> */}



        <div className="About_section">
          <div className="About_section_1"> <label className="term">代 表 | </label><span>小堀 洋平</span></div>
          <div className="About_section_2"> <label className="term">商 号 | </label><span id="About_section_name">Rayns Architect 合同会社</span></div>
          <div className="About_section_3"> <label className="term">設 立 | </label><span>2022年8月1日</span></div>
          <div className="About_section_4"> <label className="term">事 業 | </label><span>各種システム開発・コンサルティング事業・自社サービス開発・運営</span></div>
          <div className="About_section_5"> <label className="term">所在地 | </label><span>〒160-0023 東京都新宿区西新宿3-3-13 西新宿水間ビル2F</span></div>
          <div className="About_section_6"> <label className="term">E-mail | </label><span  id="About_section_name">info@rayns.jp</span></div>
        </div>


        
      </div>
    </>
  );
};

export default About;

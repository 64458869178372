import "../../App.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="Footer2">
          <div class="left-elements">
            {/* アイコン押したらTopに移動 */}
            <a href="#Top">
              <img
                className="FooterLogo"
                // public/から静的ファイルを渡す際に必要（process.env.PUBLIC_URL + ）
                src={process.env.PUBLIC_URL + "/img/logo.jpg"}
                alt="Rayns Architect LCC.ロゴ画像"
              />
            </a>
          </div>

          <div>
            <nav>
              <ul>
                <li>
                  <Link to="/">Top</Link>
                </li>
                <li>
                  <Link to="/#Mission">Mission</Link>
                </li>
                <li>
                  <Link to="/#CoreValue">CoreValue</Link>
                </li>
                <li>
                  <Link to="/#News">News</Link>
                </li>
                <li>
                  <Link to="/#Contact">Contact</Link>
                </li>
                <li>
                  <Link to="/#Recruit">Recruit</Link>
                </li>
                <li>
                  <Link to="/#Member">Member</Link>
                </li>
                <li>
                  <Link to="/#About">About</Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <span className="copyRight">©Rayns Architect LCC.</span>
      </footer>
    </>
  );
};
export default Footer;

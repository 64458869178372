import React, { useEffect, useRef, useState } from "react";

// 画像にアニメーションを追加するコンポーネント
const ImageWithAnimation = ({ src, alt }) => {
  // アニメーションが開始されたかどうかを示すステート
  const [animationCompleted, setAnimationCompleted] = useState(false);

  // 画像要素への参照を作成
  const imageRef = useRef(null);

  // useEffectフックを使用して画像の表示状態を監視
  useEffect(() => {
    // IntersectionObserverを作成（画面内に画像が入ってきたかどうかを監視する仕組み）
    const observer = new IntersectionObserver((entries) => {
      // 監視対象の各要素（今回は1つの画像）をチェック
      entries.forEach((entry) => {
        // 要素が画面内に表示され、かつアニメーションがまだ完了していない場合
        if (entry.isIntersecting && !animationCompleted) {
          // アニメーション時間を設定。画面幅が420px以下の場合は100ミリ秒、それ以外は1000ミリ秒
          const animationTime = window.innerWidth <= 420 ? 100 : 1000;

          // 一定時間後にアニメーションを完了させるタイマーを設定
          const timer = setTimeout(() => {
            setAnimationCompleted(true); // アニメーションを完了状態にする
          }, animationTime); // 設定した時間後にアニメーション完了

          // クリーンアップ関数でタイマーをクリアする（アニメーションが完了したらタイマーを停止）
          return () => clearTimeout(timer);
        }
      });
    });

    // 画像要素への現在の参照を取得
    const currentImageRef = imageRef.current;

    // 画像要素が存在する場合、監視を開始
    if (currentImageRef) {
      observer.observe(currentImageRef);
    }

    // クリーンアップ関数を返して監視を停止
    return () => {
      if (currentImageRef) {
        observer.unobserve(currentImageRef);
      }
    };
  }, [animationCompleted]); // animationCompletedが変更されたときにuseEffectを再実行

  return (
    <img
      ref={imageRef} // 画像要素への参照を設定
      className={`Section_img ${animationCompleted ? "show" : ""}`} // アニメーションが完了した場合に"show"クラスを追加
      src={src}
      alt={alt}
    />
  );
};

export default ImageWithAnimation;

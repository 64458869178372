import React from "react";
import "../../App.css";
import ImageWithAnimation from "../general/ImageWithAnimation";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import PetsIcon from "@mui/icons-material/Pets";

const Recruit = () => {
  return (
    <>
      {/* TOPのnavからのアンカーリンクを設定 */}
      <div id="Recruit" class="anchor"></div>

      <svg className="line">
        <line x1="15%" y1="0" x2="85%" y2="0" />
      </svg>

      <div className="Recruit">
        <ImageWithAnimation
          // public/から静的ファイルを渡す際に必要（process.env.PUBLIC_URL + ）
          src={process.env.PUBLIC_URL + "/img/Recruit.png"}
          alt="Recruit画像"
        />

        <div className="Recruit_section">
          <p>
            私たちと一緒を働きませんか？
            <br />
            採用情報の詳細は下記をご確認ください
          </p>

          <Link to="/RecruitSub">
            <Button
              variant="contained"
              style={{
                marginTop: "20px",
                width: "200px",
                backgroundColor: "#ffffff",
                color: "#000000",
                fontWeight: "bold",
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 14px 20px",
                borderRadius: "20px",
                borderColor: "#000000",
                borderWidth: "1px",
                borderStyle: "solid"
              }}
              endIcon={<PetsIcon style={{ color: "#b960fb" }} />}
            >
              <span style={{ marginTop: "2px" }}>採用情報</span>
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Recruit;

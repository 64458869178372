import "../../App.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="Footer2">
          <div class="left-elements">
            {/* アイコン押したらTopに移動 */}
            <a href="#Top">
              <img
                className="FooterLogo"
                // public/から静的ファイルを渡す際に必要（process.env.PUBLIC_URL + ）
                src={process.env.PUBLIC_URL + "/img/logo.jpg"}
                alt="Rayns Architect LCC.ロゴ画像"
              />
            </a>
          </div>

          <div>
            <nav>
              <ul>
                <li>
                  <Link to="/">Top</Link>
                </li>
                <li>
                  <a href="#Mission">Mission</a>
                </li>
                <li>
                  <a href="#CoreValue">CoreValue</a>
                </li>
                <li>
                  <a href="#News">News</a>
                </li>
                <li>
                  <a href="#Contact">Contact</a>
                </li>
                <li>
                  <a href="#Recruit">Recruit</a>
                </li>
                <li>
                  <a href="#Member">Member</a>
                </li>
                <li>
                  <a href="#About">About</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <span className="copyRight">©Rayns Architect LCC.</span>
      </footer>
    </>
  );
};
export default Footer;

import "../../App.css";
import SubHeader from "../general/SubHeader";
import SubFooter from "../general/SubFooter";
import Form from "../general/Form";
import Scroll from "../general/Scroll";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { useMediaQuery } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const ContactSub = () => {
  // メディアクエリを使用して画面サイズを取得
  const isSmallScreen = useMediaQuery("(max-width:750px)");

  // formの変数・関数の値をformコンポーネントから取得
  const {
    isComplete,
    isSubmitting,
    openSuccessSnackbar,
    openErrorSnackbar,
    form,
    sendEmail,
    handleClick,
    handleCloseSuccessSnackbar,
    handleCloseErrorSnackbar,
    handleHomeRedirect,
  } = Form();

  return (
    <>
      <SubHeader />
      <Scroll />
      <div className="formSub">
        <div className="SubImg">
          <img
            className="ContactSubImg"
            src={process.env.PUBLIC_URL + "/img/Contact.png"}
            alt="Contactフォーム画像"
          />
        </div>

        <p>以下のフォームに必要事項を入力してください。</p>

        <form ref={form} onSubmit={sendEmail}>
          <TextField
            required // 必須項目
            label="氏名"
            variant="standard" // テキストフィールドの下線のみが強調されるシンプルなスタイル
            style={{ width: isSmallScreen ? "80%" : "40%", marginTop: "20px" }}
            type="text"
            name="contact_name"
            disabled={isSubmitting} // isSubmittingがtrueの場合、フィールドは無効化され、ユーザーは入力できなくなる
          />

          <TextField
            required
            label="氏名（ふりがな）"
            variant="standard"
            style={{ width: isSmallScreen ? "80%" : "40%", marginTop: "20px" }}
            type="text"
            name="contact_hurigana"
            disabled={isSubmitting}
          />

          <TextField
            required
            label="TEL"
            variant="standard"
            style={{ width: isSmallScreen ? "80%" : "40%", marginTop: "20px" }}
            type="text"
            name="contact_tel"
            disabled={isSubmitting}
          />

          <TextField
            required
            label="mail"
            variant="standard"
            style={{ width: isSmallScreen ? "80%" : "40%", marginTop: "20px" }}
            type="text"
            name="contact_mail"
            disabled={isSubmitting}
          />

          <TextField
            label="お問い合わせ内容"
            multiline // 複数行の入力を許可するテキストエリア
            rows={10} // テキストエリアは10行分の高さで表示
            variant="outlined" // テキストフィールド全体が枠で囲まれるスタイル
            style={{ width: isSmallScreen ? "80%" : "40%", marginTop: "20px" }}
            type="text"
            name="contact_text"
            disabled={isSubmitting}
          />

          {/* // フォームが送信されたかどうかでボタンの表示が変わる */}
          {/* isCompleteがtrueの場合は「HOMEへ戻る」ボタンが表示 */}
          <Stack direction="row" spacing={2}>
            {isComplete ? (
              <Button
                variant="contained"
                style={{
                  width: "150px",
                  height: "50px",
                  marginTop: "20px",
                  backgroundColor: "#2e7d32",
                }}
                onClick={handleHomeRedirect}
              >
                HOMEへ戻る
              </Button>
            ) : (
              // isCompleteがfalseの場合は「送信」ボタンが表示
              <Button
                variant="contained"
                style={{
                  width: "100px",
                  height: "50px",
                  marginTop: "20px",
                  backgroundColor: "#b960fb",
                }}
                endIcon={<SendIcon style={{ margin: "0" }} />}
                type="submit"
                disabled={isSubmitting}
                onClick={handleClick}
              >
                送信
              </Button>
            )}

            {/* 送信状況によってアラートの情事が変わる */}
            <Snackbar
              open={openSuccessSnackbar}
              autoHideDuration={5000} // アラートが非表示になるまで5秒かかる
              onClose={handleCloseSuccessSnackbar}
              anchorOrigin={{
                // MUIが提供する専用のプロパティで簡単にスタイルを設定できる
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Alert
                onClose={handleCloseSuccessSnackbar}
                severity="success"
                variant="filled"
                sx={{ width: "100%" }}
              >
                送信完了しました。ご連絡まで少々お待ちください。
              </Alert>
            </Snackbar>

            <Snackbar
              open={openErrorSnackbar}
              autoHideDuration={5000}
              onClose={handleCloseErrorSnackbar}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Alert
                onClose={handleCloseErrorSnackbar}
                severity="error"
                variant="filled"
                sx={{ width: "100%" }}
              >
                送信失敗しました。再度お試しください。
              </Alert>
            </Snackbar>
          </Stack>
        </form>
      </div>
      <SubFooter />
    </>
  );
};

export default ContactSub;

import React from "react";
import "../../App.css";
import ImageWithAnimation from "../general/ImageWithAnimation";

const News = () => {
  return (
    <>
      {/* TOPのnavからのアンカーリンクを設定 */}
      <div id="News" class="anchor"></div>

      <svg className="line">
        <line x1="15%" y1="0" x2="85%" y2="0" />
      </svg>

      <div className="News">
        <ImageWithAnimation
          // public/から静的ファイルを渡す際に必要（process.env.PUBLIC_URL + ）
          src={process.env.PUBLIC_URL + "/img/News.png"}
          alt="News画像"
        />

        <ul className="News_section">
          {/* <li className="empty"><span>◆ 2024/08/01</span><br />    創業２年を迎えました！</li> */}
          <li className="empty"><span>◆ 2024/06/30</span><br />    ホームページリニューアルしました</li>
          <li className="empty"><span>◆ 2022/08/01</span><br />    会社設立しました</li>
        </ul>
      </div>
    </>
  );
};

export default News;

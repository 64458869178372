import React from "react";
import "../../App.css";
import SubHeader from "../general/SubHeader";
import SubFooter from "../general/SubFooter";
import Scroll from "../general/Scroll";
import Form from "../general/Form";
import { useMediaQuery } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const RecruitSub = () => {
  // メディアクエリを使用して画面サイズを取得
  const isSmallScreen = useMediaQuery("(max-width:750px)");

  // formの変数・関数の値をformコンポーネントから取得
  const {
    isComplete,
    isSubmitting,
    openSuccessSnackbar,
    openErrorSnackbar,
    form,
    sendEmail,
    handleClick,
    handleCloseSuccessSnackbar,
    handleCloseErrorSnackbar,
    handleHomeRedirect,
  } = Form();

  return (
    <>
      <SubHeader />
      <Scroll />
      <div className="formSub">
        <div className="SubImg">
          <img
            className="RecruitSubImg"
            src={process.env.PUBLIC_URL + "/img/Recruit.png"}
            alt="Recruitフォーム画像"
          />
        </div>

        <p>以下のフォームに必要事項を入力してください。</p>

        <form ref={form} onSubmit={sendEmail}>
          <TextField
            required // 必須項目
            label="氏名"
            variant="standard" // テキストフィールドの下線のみが強調されるシンプルなスタイル
            style={{ width: isSmallScreen ? "80%" : "40%", marginTop: "20px" }}
            type="text"
            name="recruit_name"
            disabled={isSubmitting} // isSubmittingがtrueの場合、フィールドは無効化され、ユーザーは入力できなくなる
          />
          <TextField
            required
            label="氏名（ふりがな）"
            variant="standard"
            style={{ width: isSmallScreen ? "80%" : "40%", marginTop: "20px" }}
            type="text"
            name="recruit_hurigana"
            disabled={isSubmitting}
          />
          <TextField
            required
            label="TEL"
            variant="standard"
            style={{ width: isSmallScreen ? "80%" : "40%", marginTop: "20px" }}
            type="text"
            name="recruit_tel"
            disabled={isSubmitting}
          />
          <TextField
            required
            label="mail"
            variant="standard"
            style={{
              width: isSmallScreen ? "80%" : "40%",
              marginTop: "20px",
              marginBottom: "20px",
            }}
            type="text"
            name="recruit_mail"
            disabled={isSubmitting}
          />

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="生年月日"
              style={{ width: isSmallScreen ? "80%" : "40%" }}
              type="date"
              name="recruit_date"
              disabled={isSubmitting}
            />
          </LocalizationProvider>
          <FormControl
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <FormLabel style={{ marginRight: "20px" }}>性別</FormLabel>

            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              style={{ margintop: "20px", height: "65px" }}
            >
              <FormControlLabel
                value="男性"
                control={<Radio />}
                style={{ margintop: "20px", height: "65px" }}
                label="男性"
                name="gender"
                disabled={isSubmitting}
              />

              <FormControlLabel
                value="女性"
                control={<Radio />}
                style={{ margintop: "20px", height: "65px" }}
                label="女性"
                name="gender"
                disabled={isSubmitting}
              />
              <FormControlLabel
                value="未回答"
                control={<Radio />}
                style={{ margintop: "20px", height: "65px" }}
                label="未回答"
                name="gender"
                disabled={isSubmitting}
              />
            </RadioGroup>
          </FormControl>
          <TextField
            label="志望動機/希望条件 等"
            multiline
            rows={10}
            variant="outlined"
            style={{ width: isSmallScreen ? "80%" : "40%" }}
            type="text"
            name="recruit_text"
            disabled={isSubmitting}
          />
          {/* // フォームが送信されたかどうかでボタンの表示が変わる */}
          {/* isCompleteがtrueの場合は「HOMEへ戻る」ボタンが表示 */}
          <Stack direction="row" spacing={2}>
            {isComplete ? (
              <Button
                variant="contained"
                style={{
                  width: "150px",
                  height: "50px",
                  marginTop: "20px",
                  backgroundColor: "#2e7d32",
                }}
                onClick={handleHomeRedirect}
              >
                HOMEへ戻る
              </Button>
            ) : (
              // isCompleteがfalseの場合は「送信」ボタンが表示
              <Button
                variant="contained"
                style={{
                  width: "100px",
                  height: "50px",
                  marginTop: "20px",
                  backgroundColor: "#b960fb",
                }}
                endIcon={<SendIcon style={{ margin: "0" }} />}
                type="submit"
                disabled={isSubmitting}
                onClick={handleClick}
              >
                送信
              </Button>
            )}

            {/* 送信状況によってアラートの情事が変わる */}
            <Snackbar
              open={openSuccessSnackbar}
              autoHideDuration={5000} // アラートが非表示になるまで5秒かかる
              onClose={handleCloseSuccessSnackbar}
              anchorOrigin={{
                // MUIが提供する専用のプロパティで簡単にスタイルを設定できる
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Alert
                onClose={handleCloseSuccessSnackbar}
                severity="success"
                variant="filled"
                sx={{ width: "100%" }}
              >
                送信完了しました。ご連絡まで少々お待ちください。
              </Alert>
            </Snackbar>

            <Snackbar
              open={openErrorSnackbar}
              autoHideDuration={5000}
              onClose={handleCloseErrorSnackbar}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Alert
                onClose={handleCloseErrorSnackbar}
                severity="error"
                variant="filled"
                sx={{ width: "100%" }}
              >
                送信失敗しました。再度お試しください。
              </Alert>
            </Snackbar>
          </Stack>
        </form>
      </div>
      <SubFooter />
    </>
  );
};

export default RecruitSub;

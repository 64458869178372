import Header from "../components/general/Header";
import Top from "../components/home/Top";
import Mission from "../components/home/Mission";
import CoreValue from "../components/home/CoreValue";
import News from "../components/home/News";
import Contact from "../components/home/Contact";
import Recruit from "../components/home/Recruit";
import Member from "../components/home/Member";
import About from "../components/home/About";
import Footer from "../components/general/Footer";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

function Home() {
  // ページ遷移後、スクロール位置を先頭にする。scrollToはページ全体を対象にしてスクロール
  window.scrollTo({ top: 0, behavior: "instant" });

  // ページ遷移後、ハッシュに基づいて指定のセクションにスクロール
  const location = useLocation(); // 現在のURLの場所を取得

  useEffect(() => {
    if (location.hash) {
      // URLにhash(#)が含まれているかチェック
      const element = document.getElementById(location.hash.substring(1)); // ハッシュ(#を除いた)に対応する要素を取得
      if (element) {
        // 要素が存在するかチェック
        element.scrollIntoView({ behavior: "smooth" }); // scrollIntoViewは特定の要素が可視範囲になるようにスクロール
      }
    }
  }, [location]);

  return (
    <>
      <Header />
      <Top />
      <Mission />
      <CoreValue />
      <News />
      <Contact />
      <Recruit />
      <Member />
      <About />
      <Footer />
    </>
  );
}

export default Home;

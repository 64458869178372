import "../../App.css";
import React, { useState } from 'react';
import { Link } from "react-router-dom";
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';

const SubHeader = () => {

  const [color, setColor] = useState('#000000');

  return (
    <>
      <header>
        <div className="header2">
          <div className="left-elements">
            {/* アイコン押したらTopに移動*/}
            <Link to="/">
              <img
                className="HeaderLogo"
                src={process.env.PUBLIC_URL + "/img/logo.jpg"}
                alt="Contact問い合わせフォーム_会社ロゴ画像"
              />
            </Link>
            <h3>Rayns Architect 合同会社</h3>
          </div>

          <nav>
            <ul>
              <Link to="/"
              style={{ textDecoration: 'none' }} // Remove underline from Link
              onMouseDown={() => setColor('#b960fb')}
              onMouseUp={() => setColor('#000000')}
              onMouseLeave={() => setColor('#000000')}>
              <ArrowCircleLeftIcon style={{ fontSize: "32px" , color}} />
              </Link>
            </ul>
          </nav>
        </div>
      </header>
    </>
  );
};

export default SubHeader;

import React from "react";
import "../../App.css";
import ImageWithAnimation from "../general/ImageWithAnimation";

const Member = () => {
  return (
    <>
      {/* TOPのnavからのアンカーリンクを設定 */}
      <div id="Member" class="anchor"></div>

      <svg className="line">
        <line x1="15%" y1="0" x2="85%" y2="0" />
      </svg>

      <div className="Member">
        <ImageWithAnimation
          // public/から静的ファイルを渡す際に必要（process.env.PUBLIC_URL + ）
          src={process.env.PUBLIC_URL + "/img/Member.png"}
          alt="Member画像"
        />

        <div className="Member_section">
          <p>代表：小堀 洋平</p>
          <p className="Member_text">
            大規模システム開発からアジャイル開発、さまざまなB2B、B2C、生成AIアプリ開発に携わる。
          </p>
          <p className="Member_text">
            多種多様なシステム・開発言語にて活用してきたシステム構築に欠かせないエンジニアリングスキルを豊富に有する。
          </p>
          {/* <img
            className="Member_photo"
            src={`${process.env.PUBLIC_URL}/img/Member_1.jpg`}
            alt="Menber写真(代表)"
          /> */}
        </div>
      </div>
    </>
  );
};

export default Member;
